import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// import StatusCodeS from '../images/statuscode-s-black.svg';
import StatusCodeS from '../images/statuscode-s-black-on-green-square.svg';
import StatusCodeLogo from '../images/statuscode-black-on-green.svg';

const Header = ({ siteTitle }) => (
  <header
    className="page-header"
  >
    <div className="page-header__statuscode-s" style={{
      backgroundImage: `url(${StatusCodeS})`,
    }} />
    <div className="page-header__content">
      <h1 className="page-header__statuscode-logo" style={{
        backgroundImage: `url(${StatusCodeLogo})`,
      }}>
        <Link to="/">
          <span>{siteTitle}</span>
        </Link>
      </h1>
      <nav className="page-header__navigation">
        <ul>
          <li>Contact</li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
