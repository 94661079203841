/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="page-content">
          {children}
        </main>
        <footer className="page-footer">
          <div className="page-footer__content">
            <p className="info">
              <strong>Status Code</strong><br/>
              Vredebest 31<br/>
              1191 PM<br/>
              Ouderkerk aan de Amstel
            </p>
            <p className="info">
              <a href="mailto:info@statuscode.nl">
                info@statuscode.nl
              </a>
              <br/>
              <a href="tel:0031624513821">06 245 138 21</a><br/>
              statuscode.nl<br/>
              KvK 73476633
            </p>
            <p className="copyright">
              © {new Date().getFullYear()} Status Code &nbsp;&#47;&#47;&nbsp; for digital well-being
            </p>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
